dayjs.extend(window.dayjs_plugin_customParseFormat);

// loader - Preloader
$(window).on('load', function(){
    $('#status').fadeOut();
    $('#preloader').delay(350).fadeOut('slow');
});

if ($("#login_form").length > 0){
    let redirect = "";
    redirect = get_parameter_by_name("redirect");
    let url =  document.location.toString();
    let hash = url.split("#")[1];
    if (hash != undefined) redirect += "#" + hash;

    let params_open_viewing = get_parameter_by_name("open_viewing");
    let params_open_market_appraisal = get_parameter_by_name("open_market_appraisal");
    let params_open_appointment = get_parameter_by_name("open_appointment");

    if (params_open_viewing != "") redirect += "?open_viewing=" + params_open_viewing;
    else if (params_open_market_appraisal != "") redirect += "?open_market_appraisal=" + params_open_market_appraisal;
    else if (params_open_appointment != "") redirect += "?open_appointment=" + params_open_appointment;

    $("#login_form input[name='redirect']").val(redirect);

    //***************************************
    // focus on username
    //***************************************

    $("#login_form input[name='username']").trigger("focus");

    //***************************************
    // validate
    //***************************************

    $("#login_form").validate({
        submitHandler: function(form){
            form.submit();
        },
        focusInvalid: true,
        highlight: function (element, errorClass, validClass){
            $(element).addClass("is-invalid").removeClass("is-valid");
        },
        unhighlight: function (element, errorClass, validClass){
            $(element).removeClass("is-invalid"); // $(element).addClass("is-valid").removeClass("is-invalid");
        },
        rules: {
            username: {
                required: true
            },
            password: {
                required: true
            }
        },
        ignore: "",
        messages: {
            username: {
                required: "Username required"
            },
            password: {
                required: "Password required"
            }
        }
    });
}

function get_parameter_by_name(name){
    name = name.replace(/[\[]/, "\\\[").replace(/[\]]/, "\\\]");

    let regex = new RegExp("[\\?&]" + name + "=([^&#]*)");
    let results = regex.exec(location.search);

    return results == null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
}

if ($("body.authentication-bg").length > 0){
    const date = new Date();
    let day = date.getDate();
    let month = date.getMonth() + 1;

    let season_date_count = 0;

    let photo_number = 1;
    let number_of_days_in_month = 30;
    let number_of_images_in_season = 1;

    let season = "";
    if (month >= 3 && month <= 5){
        number_of_images_in_season = 9;

        season = "1_spring";

        number_of_days_in_month = dayjs().month(2).daysInMonth();
        number_of_days_in_month = number_of_days_in_month + dayjs().month(3).daysInMonth();
        number_of_days_in_month = number_of_days_in_month + dayjs().month(4).daysInMonth();

        season_date_count = dayjs().diff(dayjs().date(1).month(2), 'day');
    }else if (month >= 6 && month <= 8){
        number_of_images_in_season = 6;

        season = "2_summer";

        number_of_days_in_month = dayjs().month(5).daysInMonth();
        number_of_days_in_month = number_of_days_in_month + dayjs().month(6).daysInMonth();
        number_of_days_in_month = number_of_days_in_month + dayjs().month(7).daysInMonth();

        season_date_count = dayjs().diff(dayjs().date(1).month(5), 'day');
    }else if (month >= 9 && month <= 11){
        number_of_images_in_season = 4;

        season = "3_autumn";

        number_of_days_in_month = dayjs().month(8).daysInMonth();
        number_of_days_in_month = number_of_days_in_month + dayjs().month(9).daysInMonth();
        number_of_days_in_month = number_of_days_in_month + dayjs().month(10).daysInMonth();

        season_date_count = dayjs().diff(dayjs().date(1).month(8), 'day');
    } else {
        number_of_images_in_season = 4;

        season = "4_winter";

        number_of_days_in_month = dayjs().month(11).daysInMonth();
        number_of_days_in_month = number_of_days_in_month + dayjs().month(0).daysInMonth();
        number_of_days_in_month = number_of_days_in_month + dayjs().month(1).daysInMonth();

        if (month == 12){
            season_date_count = dayjs().diff(dayjs().date(1).month(11), 'day');
        } else {
            season_date_count = dayjs().diff(dayjs().date(1).month(11).subtract(1, 'year'), 'day');
        }

    }

    season_date_count++; // Fixing date diff as diff starts on the first date effectively ignoring it.

    change_photo_every_x_days = Math.ceil(number_of_days_in_month / number_of_images_in_season);

    photo_number = Math.ceil(season_date_count / change_photo_every_x_days);

    // console.log("number_of_days_in_month: " + number_of_days_in_month);
    // console.log("number_of_images_in_season: " + number_of_images_in_season);
    // console.log("change_photo_every_x_days: " + change_photo_every_x_days);
    // console.log("season_date_count / change_photo_every_x_days: " + season_date_count / change_photo_every_x_days);
    // console.log("Math.ceil(season_date_count / change_photo_every_x_days): " + Math.ceil(season_date_count / change_photo_every_x_days));

    // console.log("Season Day: " + season_date_count + " | Day: " + day + ", Month: " + month);
    // console.log("Season: " + season);
    // console.log("Photo Number: " + photo_number);


    // Tmp hard code
    // season = "1_spring";
    // photo_number = 1;
    $("body.authentication-bg").css({"background-image": "url(" + base_url + "assets/images/login_page/" + season + "/" + photo_number + ".jpg)", "background-size": "cover",   "background-repeat": "no-repeat",
    "background-position": "center center"});
}